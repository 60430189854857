import React, { useState, useEffect } from "react";
import Header from "./common/Header";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Footer from "./common/Footer";

import { GrDownload } from "react-icons/gr";

import Loader from "./common/Loader";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { StyledTableRow } from "./common/styles/StyledTableRow";
import { StyledTableCell } from "./common/styles/StyledTableCell";
import { ERRORS } from "./common/constants/ConstantsErrors";
import { EMPTY } from "./common/constants/ConstantsNumbers";
import { ERRORSERVICE } from "./common/constants/ConstantsNumbers";
import image from "../assets/img/OSHIPP_LOGO-04.png";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";

export default function FailedQueries({ user, identify, name, onError }) {
  const api = process.env.REACT_APP_API_FAILEDQUERIES;
  const key = process.env.REACT_APP_API_KEY;
  let navigate = useNavigate();
  var historyObj = window.history;

  const regresar = (
    <button
      className="left-footer"
      onClick={() => {
        navigate("/menu");
      }}
    >
      Regresar
    </button>
  );

  useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  let [info, setInfo] = useState([]);

  useEffect(() => {
    axios
      .post(api, {
        usuario: identify,
        api_key: key,
      })
      .then(function (response) {
        if (response.data) {
          let aux = [];
          aux = response.data.sort((firstElement, secondElement) => {
            if (firstElement.FDFECHA < secondElement.FDFECHA) {
              return 1;
            }
            if (firstElement.FDFECHA > secondElement.FDFECHA) {
              return -1;
            }
            return 0;
          });
          setInfo(aux);
        } else {
          onError(ERRORS.Empty);
          navigate("/errorService");
        }
      })
      .catch(function (error) {
        if (error.response.status === ERRORSERVICE) {
          onError(ERRORS.Service);
        } else {
          onError(ERRORS.Generic);
        }
        navigate("/errorService");
      });
  }, [api, identify, key, navigate, onError]);

  let [infoTable, setInfoTable] = useState([]);
  useEffect(() => {
    let aux = [];
    if (info.length > 0) {
      info.forEach((item) => {
        aux.push({
          usuario: user,
          origen: item.FCPUERTAORIGEN,
          destino: item.FCPUERTADESTINO,
          fecha: formatDate(item.FDFECHA),
          error: formatError(getError(item.FCERROR)),
        });
      });
    }
    setInfoTable(aux);
  }, [info, user]);

  const getError = (data) => {
    let cad = data.substring(1, data.length - 1);
    let aux = "{" + cad + "}";
    let json = JSON.parse(aux);
    return json.rows[0].elements[0].status;
  };

  const formatDate = (dateString) => {
    let aux = dateString.replace("T", " ");
    return aux;
  };

  const downloadPdf = () => {
    const newDate = new Date();
    let date = "";
    date = `${newDate.getDate()}-${
      newDate.getMonth() + 1
    }-${newDate.getFullYear()}`;

    const addFooters = (document) => {
      const pageCount = document.internal.getNumberOfPages();
      document.setFontSize(8);
      for (var i = 1; i <= pageCount; i++) {
        document.setPage(i);
        document.text(187, 290, String(i) + " de " + String(pageCount));
      }
    };

    const doc = new jsPDF();

    doc.addImage(image, "JPEG", 10, 5, 55, 30);

    doc.text("Relación de consultas", 80, 20);

    doc.setFontSize(12);
    doc.text(date, 170, 20);

    doc.setFontSize(12);
    doc.text(user, 16, 40);

    doc.setFillColor(245, 245, 245);
    doc.rect(14.2, 45, 181.7, 15, "F");

    doc.setFontSize(10);
    doc.text("Detalle", 16, 50);
    doc.line(15, 52, 195, 52);

    doc.autoTable({
      margin: { top: 55 },
      body: infoTable,
      columns: [
        { header: "Origen", dataKey: "origen" },
        { header: "Destino", dataKey: "destino" },
        { header: "Fecha", dataKey: "fecha" },
        { header: "Descripción", dataKey: "error" },
      ],
      styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
    });

    addFooters(doc);
    doc.save("relacion-consultas-fallidas.pdf");
  };

  const formatError = (message) => {
    switch (message) {
      case ERRORS.NotFound.description:
        return ERRORS.NotFound.message;
      case ERRORS.ZeroResults.description:
        return ERRORS.ZeroResults.message;
      case ERRORS.MaxRouteLength.description:
        return ERRORS.MaxRouteLength.message;
      default:
        return ERRORS.Generic.message;
    }
  };

  return (
    <>
      {infoTable.length <= EMPTY ? (
        <Loader />
      ) : (
        <>
          <Header
            black="Relación"
            blue="de consultas"
            user={user}
            name={name}
          />
          <div className="container3">
            <div className="sr-container-table">
              <button className="fq-btn-pdf" onClick={downloadPdf}>
                <GrDownload /> PDF
              </button>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 415 }}>
                  <Table>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>
                          <b>Usuario</b>
                        </StyledTableCell>
                        <StyledTableCell>
                          <b>Consulta descartada</b>
                        </StyledTableCell>
                        <StyledTableCell>
                          <b>Fecha</b>
                        </StyledTableCell>
                        <StyledTableCell>
                          <b>Descripción</b>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {infoTable.map((item, index) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell>{user}</StyledTableCell>
                            <StyledTableCell>
                              Origen: {item.origen} <br />
                              Destino: {item.destino}
                            </StyledTableCell>
                            <StyledTableCell>
                              {formatDate(item.fecha)}
                            </StyledTableCell>
                            <StyledTableCell>{item.error}</StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
          <Footer reg={regresar} />
        </>
      )}
    </>
  );
}
