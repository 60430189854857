import React from "react";
//mui modal
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Logo from "../../../assets/img/OSHIPP_LOGO-04.png";
import "../../../assets/css/login.css";
import { styleRecoverPassword } from "../styles/Boxes";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import {
  RECOVER_PASSWORD,
  MESSAGE_SENDLINK,
  SEND,
} from "../constants/ConstantsMessages";
import { EMAIL_USER, REQUIRE_EMAIL } from "../constants/ConstantsValidations";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  Email: Yup.string().email(EMAIL_USER).required(REQUIRE_EMAIL),
});

export default function RecoverPassword({ open, handleClose }) {
  const api = process.env.REACT_APP_LOGIN;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleRecoverPassword}>
        <div className="center-items">
          <img src={Logo} id="rp-logo" alt="" />
        </div>
        <div id="rp-title">{RECOVER_PASSWORD}</div>
        <Typography id="rp-description" align="center">
          {MESSAGE_SENDLINK}
        </Typography>
        <Formik
          initialValues={{
            Email: "",
          }}
          validationSchema={DisplayingErrorMessagesSchema}
          onSubmit={(values) => {
            axios
              .post(api + "/api/start-recovery", {
                email: values.Email,
              })
              .then(function (response) {
                handleClose();
              })
              .catch(function (error) {
                handleClose();
              });
          }}
        >
          {({ errors, touched }) => (
            <Form id="rp-login-form">
              <div className="center-items">
                <Field
                  id="rp-input"
                  name="Email"
                  placeholder="Ingresa tu email"
                />
              </div>
              {touched.Email && errors.Email && (
                <div className="login-error center-items mb-error">
                  {errors.Email}
                </div>
              )}

              <button className="btn-ro-modal" type="submit">
                {SEND}
              </button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
