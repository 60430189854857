const styleDetailInPort = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleErrorPage = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 180,
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  backgroundColor: "white",
  color: "black",
  textAlign: "center",
};

const styleRecoverPassword = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  color: "#a1a1a1",
};

const styleNewPassword = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  boxShadow: 24,
  p: 3,
  backgroundColor: "white",
  color: "#a1a1a1",
};

const styleUserMenu = {
  position: "absolute",
  top: "25%",
  left: "87%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export {
  styleDetailInPort,
  styleErrorPage,
  styleRecoverPassword,
  styleNewPassword,
  styleUserMenu,
};
