import React, { useState, useEffect } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

import Loader from "./common/Loader";
import { StyledTableRowRouteItinerary } from "./common/styles/StyledTableRow";
import { StyledTableCellRouteItinerary } from "./common/styles/StyledTableCell";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";
import { ERRORS } from "./common/constants/ConstantsErrors";
import { EMPTY, ERRORSERVICE } from "./common/constants/ConstantsNumbers";

export default function RouteItinerary({ shipping, user, name, onError }) {
  const apiItinerary = process.env.REACT_APP_API_ITINERARY;
  const key = process.env.REACT_APP_API_KEY;
  var historyObj = window.history;
  //para footer
  let navigate = useNavigate();
  const regresar = (
    <button
      className="left-footer"
      onClick={() => {
        navigate("/shippingLines");
      }}
    >
      Regresar
    </button>
  );

  //post axios service prod 81 ---------------------------------------------------------------------
  let [info, setInfo] = useState([]);

  useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  function sortItinerary(array) {
    array.sort((firstElement, secondElement) => {
      if (firstElement.FILLEGADA < secondElement.FILLEGADA) {
        return -1;
      }
      if (firstElement.FILLEGADA > secondElement.FILLEGADA) {
        return 1;
      }
      if (firstElement.FISALIDA < secondElement.FISALIDA) {
        return -1;
      }
      if (firstElement.FISALIDA > secondElement.FISALIDA) {
        return 1;
      }
      return 0;
    });
    return array;
  }

  useEffect(() => {
    axios
      .post(apiItinerary, {
        Linea_naviera: shipping,
        api_key: key,
      })
      .then(function (response) {
        if (response.data) {
          let aux = sortItinerary(response.data);
          setInfo(aux);
        } else {
          onError(ERRORS.Empty);
          navigate("/errorService");
        }
      })
      .catch(function (error) {
        if (error.response.status === ERRORSERVICE) {
          onError(ERRORS.Service);
        } else {
          onError(ERRORS.Generic);
        }
        navigate("/errorService");
      });
  }, [apiItinerary, key, navigate, onError, shipping]);

  //return --------------------------------------------------------------------------------------------
  return (
    <>
      {info.length <= EMPTY ? (
        <Loader />
      ) : (
        <>
          <Header black="Itinerario" blue="de ruta" user={user} name={name} />
          <div className="container1">
            <Paper sx={{ width: "50%", overflow: "hidden", marginTop: "50px" }}>
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCellRouteItinerary colSpan="5" id="size-25">
                        TIEMPO <b>EN RUTA</b>
                      </StyledTableCellRouteItinerary>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellRouteItinerary colSpan="5" id="size-15">
                        {shipping}
                      </StyledTableCellRouteItinerary>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRowRouteItinerary className="background-blue">
                      <StyledTableCellRouteItinerary>
                        orden
                      </StyledTableCellRouteItinerary>
                      <StyledTableCellRouteItinerary>
                        ciudad
                      </StyledTableCellRouteItinerary>
                      <StyledTableCellRouteItinerary>
                        país
                      </StyledTableCellRouteItinerary>
                      <StyledTableCellRouteItinerary>
                        llegada
                      </StyledTableCellRouteItinerary>
                      <StyledTableCellRouteItinerary>
                        salida
                      </StyledTableCellRouteItinerary>
                    </StyledTableRowRouteItinerary>
                    {info.map((item, index) => (
                      <StyledTableRowRouteItinerary
                        hover
                        className="table_hover"
                        key={index}
                      >
                        <StyledTableCellRouteItinerary>
                          {index + 1}
                        </StyledTableCellRouteItinerary>
                        <StyledTableCellRouteItinerary>
                          {item.FCCIUDAD}
                        </StyledTableCellRouteItinerary>
                        <StyledTableCellRouteItinerary>
                          {item.FCPAIS}
                        </StyledTableCellRouteItinerary>
                        <StyledTableCellRouteItinerary>
                          {item.FILLEGADA}
                        </StyledTableCellRouteItinerary>
                        <StyledTableCellRouteItinerary>
                          {item.FISALIDA}
                        </StyledTableCellRouteItinerary>
                      </StyledTableRowRouteItinerary>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          <Footer reg={regresar} />
        </>
      )}
    </>
  );
}
