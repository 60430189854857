import React, { useState } from "react";
export const OptionContext = React.createContext();

const MyRouteOptions = (props) => {
  const [arrayRouteOptions, setArrayRouteOptions] = useState([]);
  const [routeOptions, setRouteOptions] = useState([]);
  const [graphOptions, setGraphOptions] = useState({});
  return (
    <OptionContext.Provider
      value={{
        routeOptions,
        setRouteOptions,
        graphOptions,
        setGraphOptions,
        arrayRouteOptions,
        setArrayRouteOptions,
      }}
    >
      {props.children}
    </OptionContext.Provider>
  );
};

export default MyRouteOptions;
