const ERRORGENERIC = "Error de consulta";
const ERRORAUTHENTICATION = "Error de autenticación";
const ERRORSESSION = "Terminó su sesión";
const ERRORTIMEOUT = "Se termino el tiempo de espera";
const ERRORSERVICE = "La consulta no fue exitosa, para más detalles revisa la sección de consultas fallidas.\n No te preocupes, esta consulta fallida no genera costo";
const ERRORNOTFOUND = "El origen y/o destino de esta consulta no se pudo geocodificar";
const ERRORZERORESULTS = "No se pudo encontrar ninguna ruta entre el origen y el destino";
const ERRORMAXROUTELENGTH = "La ruta de la consulta solicitada es demasiado larga y no se puede procesar";
const ERROREMPTY = "No se encontraron datos para esta consulta";

const REDIRECT_LOGIN = "/";
const REDIRECT_MENU = "/menu";
const REDIRECT_MENUOPTIONS = "/menuOptions";

const DESCRIPTION_NOT_FOUND = "NOT_FOUND";
const DESCRIPTION_ZERO_RESULTS = "ZERO_RESULTS";
const DESCRIPTION_MAX_ROUTE_LENGTH_EXCEEDED = "MAX_ROUTE_LENGTH_EXCEEDED";

const APIBADRESPONSE =
    "No existen conexiones de lineas navieras con los parametros ingresados.";

const ERRORS = {
  Generic: { message: ERRORGENERIC, redirect: REDIRECT_MENU },
  Authentication: { message: ERRORAUTHENTICATION, redirect: REDIRECT_LOGIN },
  Session: { message: ERRORSESSION, redirect: REDIRECT_LOGIN },
  TimeOut: {
    message: ERRORTIMEOUT,
    redirect: REDIRECT_MENUOPTIONS,
  },
  Service: {
    message: ERRORSERVICE,
    redirect: REDIRECT_MENU,
  },
  NotFound: {
    message: ERRORNOTFOUND,
    description: DESCRIPTION_NOT_FOUND,
  },
  ZeroResults: {
    message: ERRORZERORESULTS,
    description: DESCRIPTION_ZERO_RESULTS,
  },
  MaxRouteLength: {
    message: ERRORMAXROUTELENGTH,
    description: DESCRIPTION_MAX_ROUTE_LENGTH_EXCEEDED,
  },
  Empty: {
    message: ERROREMPTY,
    redirect: REDIRECT_MENU,
  },
};

export { ERRORS, APIBADRESPONSE};
