const TRUST_LABEL = "% Confianza";
const TIME_OF_ARRIVAL_LABEL = "Menor tiempo de llegada";
const REVISION_LABEL = "Menor % de revisión";
const RUTE = "RUTA";
const MYOPTIONS = "Mis opciones";
const PROBABILITY_MESSAGE = "Probabilidad de revisión";
const TIME_MESSAGE = "Tiempo de llegada";
const RELIABILITY_MESSAGE = "Confiabilidad de naviera";
const DAYS = "días";
const CRITERION = "Criterio";

const CONST_PROBABILIDADSEMAFORO = "FCPROBABILIDADSEMAFORO";
const CONST_TIEMPO = "FCTIEMPO";
const CONST_CONFIABILIDAD = "FICONFIABILIDAD";

export {
  TRUST_LABEL,
  TIME_OF_ARRIVAL_LABEL,
  REVISION_LABEL,
  RUTE,
  PROBABILITY_MESSAGE,
  TIME_MESSAGE,
  RELIABILITY_MESSAGE,
  DAYS,
  CONST_CONFIABILIDAD,
  CONST_PROBABILIDADSEMAFORO,
  CONST_TIEMPO,
  MYOPTIONS,
  CRITERION,
};
