import React, { useEffect } from "react";
import "../assets/css/menu.css";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { useNavigate } from "react-router-dom";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";

export default function Menu({
  user,
  name,
  onOption,
  onOrigin,
  onDestiny,
  onDate,
  onShipping,
  onDoor,
  setUser,
  setIdentify,
  setIsLoggedIn,
  setName,
}) {
  const apiLogin = process.env.REACT_APP_LOGIN;
  let navigate = useNavigate();
  var historyObj = window.history;

  useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  useEffect(() => {
    fetch(apiLogin + "/api/user", {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data.email);
        setIdentify(data.id);
        setName(data.name);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        setIsLoggedIn(false);
      });
  }, [apiLogin, setIdentify, setIsLoggedIn, setName, setUser]);

  return (
    <>
      <Header
        black="Inteligencia"
        blue=" logística"
        user={user}
        name={name}
      />
      <div className="container">
        <div className="menu-buttons">
          <button
            className="menu-button-i"
            onClick={() => {
              onOption("");
              onOrigin("");
              onDestiny("");
              onDate("NA");
              onShipping("");
              onDoor(null);
              navigate("/enterQuery");
            }}
          >
            Conoce tus
            <br /> opciones de
            <br /> rutas
          </button>

          <button
            className="menu-button-d"
            onClick={() => {
              navigate("/latestQueries");
            }}
          >
            Consulta el <br />
            histórico
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
