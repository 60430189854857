import React, { useContext, useState } from "react";
import "../assets/css/routeOptions.css";
import Header from "./common/Header";
import { useNavigate } from "react-router-dom";
import { OptionContext } from "./context/MyRouteOptions";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { TableRow, TableCell } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { FaTrashAlt } from "react-icons/fa";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styleErrorPage } from "./common/styles/Boxes";
import { FcInfo } from "react-icons/fc";
import Button from "@mui/material/Button";

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Legend,
  Filler,
  Tooltip,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";
import {
  CRITERION,
  DAYS,
  MYOPTIONS,
  REVISION_LABEL,
  RUTE,
  TIME_MESSAGE,
  TIME_OF_ARRIVAL_LABEL,
  TRUST_LABEL,
} from "./common/constants/MessagesRouteOption";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function RouteOptionsGraph({
  user,
  name,
  onOption,
  onOrigin,
  onDestiny,
  onDoor,
}) {
  const { routeOptions } = useContext(OptionContext);
  const { graphOptions } = useContext(OptionContext);
  var { setArrayRouteOptions } = useContext(OptionContext);

  let navigate = useNavigate();
  var historyObj = window.history;

  React.useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  let [refreshOptions, setRefresh] = useState(true);
  const [indexDelete, setIndex] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const deleteOption = () => {
    if (indexDelete !== null) {
      setRefresh(false);
      setTimeout(function () {
        var indexDeleteGraph = graphOptions.datasets.findIndex(
          (object) => object.id === routeOptions[indexDelete].FIOPCION
        );
        graphOptions.datasets.splice(indexDeleteGraph, 1);
        routeOptions.splice(indexDelete, 1);
        setRefresh(true);
      }, 100);
    }
    handleCloseDelete();
  };

  const finish = () => {
    setArrayRouteOptions([]);
    onOption("");
    onDestiny("");
    onOrigin("");
    onDoor(null);
    navigate("/menu");
  };

  let [titleTooltip, setTitleTooltip] = useState("");

  function changeBodyTooltip(id) {
    let response = "";
    let indexItem = "";

    switch (titleTooltip) {
      case REVISION_LABEL:
        indexItem = routeOptions.findIndex((object) => object.FIOPCION === id);
        response = routeOptions[indexItem].FCPROBABILIDADSEMAFORO;
        break;
      case TIME_OF_ARRIVAL_LABEL:
        indexItem = routeOptions.findIndex((object) => object.FIOPCION === id);
        response = routeOptions[indexItem].FCTIEMPO;
        break;
      case TRUST_LABEL:
        indexItem = routeOptions.findIndex((object) => object.FIOPCION === id);
        response = routeOptions[indexItem].FICONFIABILIDAD;
        break;
      default:
        break;
    }

    return response;
  }

  return (
    <>
      <Header black="Opciones" blue="de rutas" user={user} name={name} />

      <div className="container1">
        <div className="container-or">
          <div className="left-or">
            <div className="acordion">
              <div className="title_my_options">
                <p>{MYOPTIONS}</p>
              </div>
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table>
                  <TableHead>
                    <TableRow id="table-align-left">
                      <TableCell>
                        <lable className="row-rute">{RUTE}</lable>
                        <lable id="row-criterio">{CRITERION}</lable>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {routeOptions.length === 0
                      ? ""
                      : routeOptions.map((item, i) => (
                          <TableRow key={i} style={{ textAlign: "left" }}>
                            <TableCell>
                              <Accordion id="accordion-principal">
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <div className="accordion_summary">
                                    <div className="item">
                                      Ruta {item.FIOPCION} - {item.FCRUTA}
                                    </div>
                                    <div
                                      className="item"
                                      style={{ textAlign: "center" }}
                                    >
                                      {item.CRITERIO === null
                                        ? "No seleccionó un criterio"
                                        : item.CRITERIO}
                                    </div>
                                    <div
                                      className="item"
                                      style={{ textAlign: "center" }}
                                    >
                                      <button
                                        className="botton_accordion"
                                        onClick={() => {
                                          setIndex(i);
                                          handleOpenDelete();
                                        }}
                                      >
                                        <FaTrashAlt />
                                      </button>
                                    </div>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="div_res_accordion">
                                    <p>
                                      {TIME_MESSAGE}: {item.FCTIEMPO} {DAYS}
                                    </p>
                                    <p>
                                      %Confianza de la naviera:{" "}
                                      {item.FICONFIABILIDAD}%
                                    </p>
                                    <p>
                                      %De revisión:{" "}
                                      {item.FCPROBABILIDADSEMAFORO}%
                                    </p>
                                    <p>
                                      Tiempo de recorrido vía terrestre (puerto
                                      salida): {item.FCTIEMPOPUERTOORIGEN}
                                    </p>
                                    <p>
                                      Tiempo de recorrido vía marítima (puerto
                                      salida): {item.FCTIEMPORECMARITIMO} días
                                    </p>
                                    <p>
                                      Tiempo de recorrido vía terrestre (puerto
                                      entrada): {item.FCTIEMPOPUERTADESTINO}
                                    </p>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div className="right-or">
            {refreshOptions ? (
              <Radar
                data={graphOptions}
                options={{
                  maintainAspectRatio: false,
                  responsive: 1,
                  scales: {
                    r: {
                      ticks: {
                        callback: function () {
                          return "";
                        },
                        backdropColor: "rgba(0, 0, 0, 0)",
                      },
                    },
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        title: function (tooltipItems) {
                          let title = tooltipItems[0].label;
                          if (title) {
                            setTitleTooltip(title);
                          }
                          return title;
                        },
                        label: function (context) {
                          let label = context.dataset.label || "";
                          let id = context.dataset.id || "";
                          if (label) {
                            label += `: ${changeBodyTooltip(id)}`;
                          }
                          return label;
                        },
                      },
                    },
                  },
                }}
              />
            ) : (
              ""
            )}

            <div className="bottons_final">
              <button
                className="btn_accordeon"
                onClick={() => {
                  navigate("/routeOption");
                }}
              >
                Regresar
              </button>
              <button className="btn_accordeon" onClick={finish}>
                Terminar
              </button>
            </div>
          </div>
        </div>

        <Modal
          open={openDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleErrorPage}>
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h4">
                <FcInfo size={40} />
              </Typography>

              <Typography id="modal-modal-description" sx={{ mt: 4, mb: 4 }}>
                La opción se quitará de la lista
              </Typography>
              <Button onClick={handleCloseDelete}>Cancelar</Button>
              <Button onClick={deleteOption}>Acepto</Button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
