import { COLORS } from "../constants/Constans";
import { FIRSTPOSITION } from "../constants/ConstantsNumbers";
import {
  PROBABILITY,
  RELIABILITY,
  TIMEOFARRIVAL,
} from "../constants/ConstantsValidation";
import {
  CONST_CONFIABILIDAD,
  CONST_PROBABILIDADSEMAFORO,
  CONST_TIEMPO,
  DAYS,
  PROBABILITY_MESSAGE,
  RELIABILITY_MESSAGE,
  REVISION_LABEL,
  RUTE,
  TIME_MESSAGE,
  TIME_OF_ARRIVAL_LABEL,
  TRUST_LABEL,
} from "../constants/MessagesRouteOption";

const orderWithOneOption = (OPTION, information = []) => {
  switch (OPTION) {
    case CONST_PROBABILIDADSEMAFORO:
      information.sort((fisrtElement, secondElement) => {
        if (
          fisrtElement.FCPROBABILIDADSEMAFORO ===
          secondElement.FCPROBABILIDADSEMAFORO
        ) {
          return 0;
        }
        if (
          fisrtElement.FCPROBABILIDADSEMAFORO <
          secondElement.FCPROBABILIDADSEMAFORO
        ) {
          return -1;
        }
        return 1;
      });
      break;

    case CONST_TIEMPO:
      information.sort((fisrtElement, secondElement) => {
        if (fisrtElement.FCTIEMPO === secondElement.FCTIEMPO) {
          return 0;
        }
        if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
          return -1;
        }
        return 1;
      });
      break;

    case CONST_CONFIABILIDAD:
      information.sort((fisrtElement, secondElement) => {
        if (fisrtElement.FICONFIABILIDAD === secondElement.FICONFIABILIDAD) {
          return 0;
        }
        if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
          return -1;
        }
        return 1;
      });
      break;

    default:
      break;
  }
  return information;
};
const orderWithTwoOption = (OPTION_ONE, OPTION_TWO, information = []) => {
  if (
    OPTION_ONE === CONST_PROBABILIDADSEMAFORO &&
    OPTION_TWO === CONST_TIEMPO
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO <
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return -1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO >
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return 1;
      }
      if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
        return -1;
      }
      if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
        return 1;
      }
      return 0;
    });
  } else if (
    OPTION_ONE === CONST_PROBABILIDADSEMAFORO &&
    OPTION_TWO === CONST_CONFIABILIDAD
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO <
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return -1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO >
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return 1;
      }
      if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
        return -1;
      }
      if (fisrtElement.FICONFIABILIDAD < secondElement.FICONFIABILIDAD) {
        return 1;
      }
      return 0;
    });
  } else if (
    OPTION_ONE === CONST_TIEMPO &&
    OPTION_TWO === CONST_PROBABILIDADSEMAFORO
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
        return -1;
      }
      if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
        return 1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO <
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return -1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO >
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return 1;
      }
      return 0;
    });
  } else if (
    OPTION_ONE === CONST_TIEMPO &&
    OPTION_TWO === CONST_CONFIABILIDAD
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
        return -1;
      }
      if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
        return 1;
      }
      if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
        return -1;
      }
      if (fisrtElement.FICONFIABILIDAD < secondElement.FICONFIABILIDAD) {
        return 1;
      }
      return 0;
    });
  } else if (
    OPTION_ONE === CONST_CONFIABILIDAD &&
    OPTION_TWO === CONST_PROBABILIDADSEMAFORO
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
        return -1;
      }
      if (fisrtElement.FICONFIABILIDAD < secondElement.FICONFIABILIDAD) {
        return 1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO <
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return -1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO >
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return 1;
      }
      return 0;
    });
  } else if (
    OPTION_ONE === CONST_CONFIABILIDAD &&
    OPTION_TWO === CONST_TIEMPO
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
        return -1;
      }
      if (fisrtElement.FICONFIABILIDAD < secondElement.FICONFIABILIDAD) {
        return 1;
      }
      if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
        return -1;
      }
      if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
        return 1;
      }
      return 0;
    });
  }

  return information;
};
const orderWithThreeOption = (
  OPTION_ONE,
  OPTION_TWO,
  OPTION_THREE,
  information = []
) => {
  if (
    OPTION_ONE === CONST_PROBABILIDADSEMAFORO &&
    OPTION_TWO === CONST_TIEMPO &&
    OPTION_THREE === CONST_CONFIABILIDAD
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO <
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return -1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO >
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return 1;
      }
      if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
        return -1;
      }
      if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
        return 1;
      }
      if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
        return -1;
      }
      if (fisrtElement.FICONFIABILIDAD < secondElement.FICONFIABILIDAD) {
        return 1;
      }
      return 0;
    });
  } else if (
    OPTION_ONE === CONST_PROBABILIDADSEMAFORO &&
    OPTION_TWO === CONST_CONFIABILIDAD &&
    OPTION_THREE === CONST_TIEMPO
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO <
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return -1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO >
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return 1;
      }
      if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
        return -1;
      }
      if (fisrtElement.FICONFIABILIDAD < secondElement.FICONFIABILIDAD) {
        return 1;
      }
      if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
        return -1;
      }
      if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
        return 1;
      }
      return 0;
    });
  } else if (
    OPTION_ONE === CONST_TIEMPO &&
    OPTION_TWO === CONST_PROBABILIDADSEMAFORO &&
    OPTION_THREE === CONST_CONFIABILIDAD
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
        return -1;
      }
      if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
        return 1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO <
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return -1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO >
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return 1;
      }
      if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
        return -1;
      }
      if (fisrtElement.FICONFIABILIDAD < secondElement.FICONFIABILIDAD) {
        return 1;
      }
      return 0;
    });
  } else if (
    OPTION_ONE === CONST_TIEMPO &&
    OPTION_TWO === CONST_CONFIABILIDAD &&
    OPTION_THREE === CONST_PROBABILIDADSEMAFORO
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
        return -1;
      }
      if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
        return 1;
      }
      if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
        return -1;
      }
      if (fisrtElement.FICONFIABILIDAD < secondElement.FICONFIABILIDAD) {
        return 1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO <
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return -1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO >
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return 1;
      }
      return 0;
    });
  } else if (
    OPTION_ONE === CONST_CONFIABILIDAD &&
    OPTION_TWO === CONST_PROBABILIDADSEMAFORO &&
    OPTION_THREE === CONST_TIEMPO
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
        return -1;
      }
      if (fisrtElement.FICONFIABILIDAD < secondElement.FICONFIABILIDAD) {
        return 1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO <
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return -1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO >
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return 1;
      }
      if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
        return -1;
      }
      if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
        return 1;
      }
      return 0;
    });
  } else if (
    OPTION_ONE === CONST_CONFIABILIDAD &&
    OPTION_TWO === CONST_TIEMPO &&
    OPTION_THREE === CONST_PROBABILIDADSEMAFORO
  ) {
    information.sort((fisrtElement, secondElement) => {
      if (fisrtElement.FICONFIABILIDAD > secondElement.FICONFIABILIDAD) {
        return -1;
      }
      if (fisrtElement.FICONFIABILIDAD < secondElement.FICONFIABILIDAD) {
        return 1;
      }
      if (fisrtElement.FCTIEMPO < secondElement.FCTIEMPO) {
        return -1;
      }
      if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
        return 1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO <
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return -1;
      }
      if (
        fisrtElement.FCPROBABILIDADSEMAFORO >
        secondElement.FCPROBABILIDADSEMAFORO
      ) {
        return 1;
      }
      return 0;
    });
  }

  return information;
};
const getObjectSetValuesProbability = (asc = new Set()) => {
  var auxAsc = [...asc];
  var auxDesc = JSON.parse(JSON.stringify(auxAsc));
  auxDesc.sort((fisrtElement, secondElement) => {
    if (fisrtElement === secondElement) {
      return 0;
    }
    if (fisrtElement < secondElement) {
      return -1;
    }
    return 1;
  });
  let objectReturn = [];
  let auxObject = {};
  auxAsc.forEach((item, i) => {
    auxObject = {
      valueAsc: item,
      valueDesc: auxDesc[i],
    };
    objectReturn.push(auxObject);
  });
  return objectReturn;
};
const formattedDataGraph = (information = []) => {
  const ascProbability = new Set();
  information.sort((fisrtElement, secondElement) => {
    if (
      fisrtElement.FCPROBABILIDADSEMAFORO ===
      secondElement.FCPROBABILIDADSEMAFORO
    ) {
      return 0;
    }
    if (
      fisrtElement.FCPROBABILIDADSEMAFORO > secondElement.FCPROBABILIDADSEMAFORO
    ) {
      return -1;
    }
    return 1;
  });
  information.forEach((item) => {
    ascProbability.add(item.FCPROBABILIDADSEMAFORO);
  });
  var objectsSetValuesProbability =
    getObjectSetValuesProbability(ascProbability);
  //setValues probability
  information.forEach((item, i) => {
    let indexItem = objectsSetValuesProbability.findIndex(
      (object) => object.valueAsc === item.FCPROBABILIDADSEMAFORO
    );
    information[i].FCPROBABILIDADSEMAFORO =
      objectsSetValuesProbability[indexItem].valueDesc;
  });
  const ascTime = new Set();
  information.sort((fisrtElement, secondElement) => {
    if (fisrtElement.FCTIEMPO === secondElement.FCTIEMPO) {
      return 0;
    }
    if (fisrtElement.FCTIEMPO > secondElement.FCTIEMPO) {
      return -1;
    }
    return 1;
  });
  information.forEach((item) => {
    ascTime.add(item.FCTIEMPO);
  });
  var objectsSetValuesTime = getObjectSetValuesProbability(ascTime);
  //setValues time
  information.forEach((item, i) => {
    let indexItem = objectsSetValuesTime.findIndex(
      (object) => object.valueAsc === item.FCTIEMPO
    );
    information[i].FCTIEMPO = objectsSetValuesTime[indexItem].valueDesc;
  });

  let auxData = {
    labels: [TRUST_LABEL, TIME_OF_ARRIVAL_LABEL, REVISION_LABEL],
    datasets: [],
  };

  let dataGraph = {};

  information.forEach((item, i) => {
    dataGraph = {
      id: item.FIOPCION,
      label: `${RUTE} ${item.FIOPCION}`,
      backgroundColor: "transparent",
      borderColor: COLORS[i],
      fill: false,
      radius: 4,
      pointRadius: 4,
      pointBorderWidth: 1,
      pointBackgroundColor: COLORS[i],
      pointBorderColor: COLORS[i],
      pointHoverRadius: 8,
      data: [
        parseInt(item.FICONFIABILIDAD),
        parseInt(item.FCTIEMPO),
        parseInt(item.FCPROBABILIDADSEMAFORO),
      ],
    };
    auxData.datasets.push(dataGraph);
  });

  return auxData;
};
const filterArray = (inputArr) => {
  var found = {};
  var out = inputArr.filter(function (element) {
    return found.hasOwnProperty(element.FIOPCION)
      ? false
      : (found[element.FIOPCION] = true);
  });
  return out;
};
const formattedAddOption = (multipleOption, OrdererInformation, index) => {
  const auxRute = {
    FCDESVTIEMPODESCARGA: null,
    FCDESVTIEMPOLLEGADA: null,
    FCDESVTIEMPOREV: null,
    FCDISTANCIAPUERTADESTINO: null,
    FCDISTANCIAPUERTOORIGEN: null,
    FCNAVIERA: null,
    FCPROBABILIDADSEMAFORO: null,
    FCPUERTADESTINO: null,
    FCPUERTAORIGEN: null,
    FCPUERTODESTINO: null,
    FCPUERTOORIGEN: null,
    FCRUTA: null,
    FCTIEMPO: null,
    FCTIEMPOCARGA: null,
    FCTIEMPODESCARGA: null,
    FCTIEMPONAVIERA: null,
    FCTIEMPOPAGO: null,
    FCTIEMPOPUERTADESTINO: null,
    FCTIEMPOPUERTOORIGEN: null,
    FCTIEMPORECMARITIMO: null,
    FCTIEMPOREVISION: null,
    FICONFIABILIDAD: null,
    FIOPCION: null,
    FIPROBABILIDADCARGA: null,
    FIPROBABILIDADDESCARGA: null,
    FIPROBABILIDADPAGO: null,
    FIUSUARIOID: null,
    CRITERIO: null,
  };

  if (multipleOption.lenght !== 0) {
    switch (multipleOption[FIRSTPOSITION]) {
      case PROBABILITY:
        auxRute.CRITERIO = `${PROBABILITY_MESSAGE}: ${OrdererInformation[index].FCPROBABILIDADSEMAFORO}%`;
        break;
      case TIMEOFARRIVAL:
        auxRute.CRITERIO = `${TIME_MESSAGE}: ${OrdererInformation[index].FCTIEMPO} ${DAYS}`;
        break;
      case RELIABILITY:
        auxRute.CRITERIO = `${RELIABILITY_MESSAGE}: ${OrdererInformation[index].FICONFIABILIDAD}%`;
        break;
      default:
        break;
    }
  }

  auxRute.FCDESVTIEMPODESCARGA = OrdererInformation[index].FCDESVTIEMPODESCARGA;
  auxRute.FCDESVTIEMPOLLEGADA = OrdererInformation[index].FCDESVTIEMPOLLEGADA;
  auxRute.FCDESVTIEMPOREV = OrdererInformation[index].FCDESVTIEMPOREV;
  auxRute.FCDISTANCIAPUERTADESTINO =
    OrdererInformation[index].FCDISTANCIAPUERTADESTINO;
  auxRute.FCDISTANCIAPUERTOORIGEN =
    OrdererInformation[index].FCDISTANCIAPUERTOORIGEN;
  auxRute.FCNAVIERA = OrdererInformation[index].FCNAVIERA;
  auxRute.FCPROBABILIDADSEMAFORO =
    OrdererInformation[index].FCPROBABILIDADSEMAFORO;
  auxRute.FCPUERTADESTINO = OrdererInformation[index].FCPUERTADESTINO;
  auxRute.FCPUERTAORIGEN = OrdererInformation[index].FCPUERTAORIGEN;
  auxRute.FCPUERTODESTINO = OrdererInformation[index].FCPUERTODESTINO;
  auxRute.FCPUERTOORIGEN = OrdererInformation[index].FCPUERTOORIGEN;
  auxRute.FCRUTA = OrdererInformation[index].FCRUTA;
  auxRute.FCTIEMPO = OrdererInformation[index].FCTIEMPO;
  auxRute.FCTIEMPOCARGA = OrdererInformation[index].FCTIEMPOCARGA;
  auxRute.FCTIEMPODESCARGA = OrdererInformation[index].FCTIEMPODESCARGA;
  auxRute.FCTIEMPONAVIERA = OrdererInformation[index].FCTIEMPONAVIERA;
  auxRute.FCTIEMPOPAGO = OrdererInformation[index].FCTIEMPOPAGO;
  auxRute.FCTIEMPOPUERTADESTINO =
    OrdererInformation[index].FCTIEMPOPUERTADESTINO;
  auxRute.FCTIEMPOPUERTOORIGEN = OrdererInformation[index].FCTIEMPOPUERTOORIGEN;
  auxRute.FCTIEMPORECMARITIMO = OrdererInformation[index].FCTIEMPORECMARITIMO;
  auxRute.FCTIEMPOREVISION = OrdererInformation[index].FCTIEMPOREVISION;
  auxRute.FICONFIABILIDAD = OrdererInformation[index].FICONFIABILIDAD;
  auxRute.FIOPCION = OrdererInformation[index].FIOPCION;
  auxRute.FIPROBABILIDADCARGA = OrdererInformation[index].FIPROBABILIDADCARGA;
  auxRute.FIPROBABILIDADDESCARGA =
    OrdererInformation[index].FIPROBABILIDADDESCARGA;
  auxRute.FIPROBABILIDADPAGO = OrdererInformation[index].FIPROBABILIDADPAGO;
  auxRute.FIUSUARIOID = OrdererInformation[index].FIUSUARIOID;

  return auxRute;
};

export {
  formattedDataGraph,
  filterArray,
  formattedAddOption,
  orderWithOneOption,
  orderWithTwoOption,
  orderWithThreeOption,
};
