import React, { useState, useEffect } from "react";
import "../assets/css/serviceRelationship.css";
import Header from "./common/Header";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import Footer from "./common/Footer";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { GrDownload } from "react-icons/gr";

import Loader from "./common/Loader";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  StyledTableRowServiceRelationship,
  StyledTableRowServiceRelationship2,
} from "./common/styles/StyledTableRow";
import { StyledTableCellServiceRelationship } from "./common/styles/StyledTableCell";
import { ERRORS } from "./common/constants/ConstantsErrors";
import { EMPTY, ERRORSERVICE } from "./common/constants/ConstantsNumbers";
import image from "../assets/img/OSHIPP_LOGO-04.png";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";

export default function ServiceRelationship({ user, identify, name, onError }) {
  const apiServiceRelationship = process.env.REACT_APP_API_FACTURATION;
  const key = process.env.REACT_APP_API_KEY;
  let navigate = useNavigate();
  var historyObj = window.history;

  const regresar = (
    <button
      className="left-footer"
      onClick={() => {
        navigate("/menu");
      }}
    >
      Regresar
    </button>
  );

  useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  //api facturacion -------------------------------------------------------------------------------------------
  let [info, setInfo] = useState([]);

  useEffect(() => {
    axios
      .post(apiServiceRelationship, {
        usuario: identify,
        api_key: key,
      })
      .then(function (response) {
        if (response.data) {
          let arrayAux = [];
          arrayAux = response.data.sort((a, b) => {
            if (a.FDFECHA > b.FDFECHA) {
              return -1;
            }
            if (a.FDFECHA < b.FDFECHA) {
              return 1;
            }
            if (a.FIMES < b.FIMES) {
              return -1;
            }
            if (a.FIMES > b.FIMES) {
              return 1;
            }
            return 0;
          });
          setMonth(arrayAux[0].FIMES);
          setInfo(arrayAux);
        } else {
          onError(ERRORS.Empty);
          navigate("/errorService");
        }
      })
      .catch(function (error) {
        if (error.response.status === ERRORSERVICE) {
          onError(ERRORS.Service);
        } else {
          onError(ERRORS.Generic);
        }
        navigate("/errorService");
      });
  }, [apiServiceRelationship, identify, key, navigate, onError]);

  let [month1, setMonth1] = useState(0);
  let [month2, setMonth2] = useState(0);
  let [month3, setMonth3] = useState(0);

  useEffect(() => {
    if (info.length !== 0) {
      setMonth1(info[0].FIMES);
      for (let i = 0; i < info.length; i++) {
        if (info[i].FIMES === info[0].FIMES - 1) {
          setMonth2(info[i].FIMES);
        }
        if (info[i].FIMES === info[0].FIMES - 2) {
          setMonth3(info[i].FIMES);
        }
      }
    }
  }, [info]);

  let [month1S, setMonth1S] = useState("---");
  let [month2S, setMonth2S] = useState("---");
  let [month3S, setMonth3S] = useState("---");

  useEffect(() => {
    setMonth1S(getMonth(month1));
  }, [month1]);

  useEffect(() => {
    setMonth2S(getMonth(month2));
  }, [month2]);

  useEffect(() => {
    setMonth3S(getMonth(month3));
  }, [month3]);

  const getMonth = (number) => {
    switch (number) {
      case 1:
        return "Enero";
      case 2:
        return "Febrero";
      case 3:
        return "Marzo";
      case 4:
        return "Abril";
      case 5:
        return "Mayo";
      case 6:
        return "Junio";
      case 7:
        return "Julio";
      case 8:
        return "Agosto";
      case 9:
        return "Septiembre";
      case 10:
        return "Octubre";
      case 11:
        return "Noviembre";
      case 12:
        return "Diciembre";
      default:
        return "---";
    }
  };

  //---------------------------------------------------------------------------------------------------------------
  const [month, setMonth] = useState(null);

  const handleChange = (event) => {
    setMonth(event.target.value);
  };
  //-----------------------------------------------------------------------------------------------------------
  const formatDate = (dateString) => {
    let aux = dateString.replace("T", " ");
    return aux;
  };

  const divicion = (a, b) => {
    let res = a / b;
    return res;
  };

  const downloadPdf = () => {
    let items = [];

    info.forEach((item) => {
      if (item.FIMES === month) {
        items.push({
          fecha: formatDate(item.FDFECHA),
          tipo: item.FCOPERACION,
          origen: item.FCPUERTAORIGEN,
          destino: item.FCPUERTADESTINO,
        });
      }
    });

    const newDate = new Date();
    let date = "";
    date = `${newDate.getDate()}-${
      newDate.getMonth() + 1
    }-${newDate.getFullYear()}`;

    const addFooters = (document) => {
      const pageCount = document.internal.getNumberOfPages();
      document.setFontSize(8);
      for (var i = 1; i <= pageCount; i++) {
        document.setPage(i);
        document.text(187, 290, String(i) + " de " + String(pageCount));
      }
    };

    const doc = new jsPDF();

    doc.addImage(image, "JPEG", 10, 5, 55, 30);

    doc.text("Relación de consultas", 80, 20);

    doc.setFontSize(12);
    doc.text(date, 170, 20);

    doc.setFontSize(12);
    doc.text(user, 16, 40);

    doc.setFillColor(245, 245, 245);
    doc.rect(14.2, 45, 181.7, 20, "F");

    doc.setFontSize(10);
    doc.text(15, 52, `Mes de facturación: ${getMonth(month)}`);
    doc.text(
      15,
      62,
      `Total de consultas en el mes: ${info[0].FITOTALCONSULTAS}`
    );

    doc.autoTable({
      margin: { top: 65 },
      body: [
        ["", "", "", "", ""],
        ["Total de consultas", "Costo unitario", "Subtotal", "IVA", "Total"],
        [
          info[0].FITOTALCONSULTAS,
          `$ ${divicion(info[0].FITOTAL, info[0].FITOTALCONSULTAS).toFixed(2)}`,
          `$ ${info[0].FISUBTOTAL.toFixed(2)}`,
          `$ ${info[0].FIIVA.toFixed(2)}`,
          `$ ${info[0].FITOTAL.toFixed(2)}`,
        ],
        ["", "", "", "", ""],
      ],
      columns: [{}, {}, {}, {}, {}],
    });

    doc.setFontSize(10);
    doc.text("Detalle", 16, 98);
    doc.line(15, 100, 195, 100);

    doc.autoTable({
      body: items,
      columns: [
        { header: "Fecha", dataKey: "fecha" },
        { header: "Típo", dataKey: "tipo" },
        { header: "Ubicación orígen", dataKey: "origen" },
        { header: "Ubicación destino", dataKey: "destino" },
      ],
      styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
    });

    addFooters(doc);
    doc.save("relacion-consultas.pdf");
  };

  //return--------------------------------------------------------------------------------------------------------
  return (
    <>
      {info.length <= EMPTY ? (
        <Loader />
      ) : (
        <>
          <Header
            black="Relación"
            blue="de consultas"
            user={user}
            name={name}
          />
          <div className="container3">
            <div className="sr-container-table">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table>
                    <TableBody>
                      <StyledTableRowServiceRelationship>
                        <StyledTableCellServiceRelationship colSpan={4}>
                          <div id="title-month-sr">
                            <b>{month1S}</b>
                          </div>
                          Mes de facturación{" "}
                          <Select
                            labelId="demo-simple-select-label"
                            onChange={handleChange}
                            id="select-service-relationship"
                            value={month}
                          >
                            <MenuItem de value={month1}>
                              {month1S}
                            </MenuItem>
                            <MenuItem value={month2}>{month2S}</MenuItem>
                            <MenuItem value={month3}>{month3S}</MenuItem>
                          </Select>
                          <p>
                            Total de consultas en el mes{" "}
                            <b>{info[0].FITOTALCONSULTAS}</b>
                          </p>
                        </StyledTableCellServiceRelationship>

                        <StyledTableCellServiceRelationship>
                          <button className="sr-btn-pdf" onClick={downloadPdf}>
                            <GrDownload /> PDF
                          </button>
                        </StyledTableCellServiceRelationship>
                      </StyledTableRowServiceRelationship>
                      <StyledTableRowServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          <b>Total de consultas</b>
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          <b>Costo unitario</b>
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          <b>Subtotal</b>
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          <b>IVA</b>
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          <b>Total</b>
                        </StyledTableCellServiceRelationship>
                      </StyledTableRowServiceRelationship>
                      <StyledTableRowServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          {info[0].FITOTALCONSULTAS}
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          $
                          {divicion(
                            info[0].FITOTAL,
                            info[0].FITOTALCONSULTAS
                          ).toFixed(2)}{" "}
                          MXN
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          ${info[0].FISUBTOTAL.toFixed(2)} MXN
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          ${info[0].FIIVA.toFixed(2)} MXN
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          ${info[0].FITOTAL.toFixed(2)} MXN
                        </StyledTableCellServiceRelationship>
                      </StyledTableRowServiceRelationship>
                      <StyledTableRowServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          <b>Detalle</b>
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship></StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship></StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship></StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship></StyledTableCellServiceRelationship>
                      </StyledTableRowServiceRelationship>
                    </TableBody>
                  </Table>

                  <Table>
                    <TableBody>
                      <StyledTableRowServiceRelationship2>
                        <StyledTableCellServiceRelationship>
                          <b>Fecha</b>
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          <b>Típo</b>
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          <b>Ubicación orígen</b>
                        </StyledTableCellServiceRelationship>
                        <StyledTableCellServiceRelationship>
                          <b>Ubicación destino</b>
                        </StyledTableCellServiceRelationship>
                      </StyledTableRowServiceRelationship2>
                      {info.map((item, index) => {
                        if (item.FIMES === month) {
                          return (
                            <StyledTableRowServiceRelationship2 key={index}>
                              <StyledTableCellServiceRelationship>
                                {formatDate(item.FDFECHA)}
                              </StyledTableCellServiceRelationship>
                              <StyledTableCellServiceRelationship>
                                {item.FCOPERACION}
                              </StyledTableCellServiceRelationship>
                              <StyledTableCellServiceRelationship>
                                {item.FCPUERTAORIGEN}
                              </StyledTableCellServiceRelationship>
                              <StyledTableCellServiceRelationship>
                                {item.FCPUERTADESTINO}
                              </StyledTableCellServiceRelationship>
                            </StyledTableRowServiceRelationship2>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
          <Footer reg={regresar} />
        </>
      )}
    </>
  );
}
