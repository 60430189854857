const MIN_CHARACTER = "No cumple con el mímimo de caracteres";
const MAX_CHARACTER = "Excedió el límite de caracteres";
const CONTAIN_CAPITAL_LETTER = "Debe contener al menos una mayúscula";
const CONTAIN_LOWERCASE = "Debe contener al menos una minúscula";
const CONTAIN_NUMBER = "Debe contener al menos un número";
const CONTAIN_SPECIAL_SIGN = "Debe contener al menos un signo especial";
const REQUIRE_PASSWORD = "Contraseña requerida";
const NO_MATCH_PASSWORD = "Contraseñas no coinciden";
const REQUIRE_MATCH = "Confirmación de contraseña requerida";
const REQUIRE_EMAIL = "Email requerido";
const EMAIL_USER = "El usuario debe ser un email";

export {
  MIN_CHARACTER,
  MAX_CHARACTER,
  CONTAIN_CAPITAL_LETTER,
  CONTAIN_LOWERCASE,
  CONTAIN_NUMBER,
  CONTAIN_SPECIAL_SIGN,
  REQUIRE_PASSWORD,
  NO_MATCH_PASSWORD,
  REQUIRE_MATCH,
  REQUIRE_EMAIL,
  EMAIL_USER,
};
