import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#e8f5f2",
  },
}));

const StyledTableRowServiceRelationship = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#a9d3ef",
  },
}));

const StyledTableRowServiceRelationship2 = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#a9d3ef",
}));

const StyledTableRowRouteItinerary = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "white",
  },
}));

export {
  StyledTableRow,
  StyledTableRowServiceRelationship,
  StyledTableRowServiceRelationship2,
  StyledTableRowRouteItinerary,
};
