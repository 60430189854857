import { Box, Modal, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { styleNewPassword } from "./common/styles/Boxes";
import * as Yup from "yup";
import Logo from "../assets/img/OSHIPP_LOGO-04.png";
import "../assets/css/login.css";
import { useNavigate, useParams } from "react-router-dom";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";
import axios from "axios";
import {
  ACEPT,
  CONFIRM,
  DESCRIPTION_PASSWORD,
  NEW_PASSWORD,
  PASSWORD,
  PASSWORD_CAPITAL_LETTER,
  SAVE,
  SUCCESS_NEW_PASSWORD,
  SUCCESS,
} from "./common/constants/ConstantsMessages";
import {
  CONTAIN_CAPITAL_LETTER,
  CONTAIN_LOWERCASE,
  CONTAIN_NUMBER,
  CONTAIN_SPECIAL_SIGN,
  MAX_CHARACTER,
  MIN_CHARACTER,
  NO_MATCH_PASSWORD,
  REQUIRE_MATCH,
  REQUIRE_PASSWORD,
} from "./common/constants/ConstantsValidations";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  Password: Yup.string()
    .min(2, MIN_CHARACTER)
    .max(8, MAX_CHARACTER)
    .matches(/[A-Z]/, CONTAIN_CAPITAL_LETTER)
    .matches(/[a-z]/, CONTAIN_LOWERCASE)
    .matches(/[1-9]/, CONTAIN_NUMBER)
    .matches(/[$@$!%*?&#¡¿]/, CONTAIN_SPECIAL_SIGN)
    .required(REQUIRE_PASSWORD),
  PasswordConfirm: Yup.string()
    .oneOf([Yup.ref("Password"), null], NO_MATCH_PASSWORD)
    .required(REQUIRE_MATCH),
});

export default function NewPassword() {
  const api = process.env.REACT_APP_LOGIN;
  let params = useParams();
  let navigate = useNavigate();
  var historyObj = window.history;

  React.useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  return (
    <>
      <Box sx={styleNewPassword}>
        <div className="center-items">
          <img src={Logo} id="rp-logo" alt="" />
        </div>
        <div id="rp-title">{NEW_PASSWORD}</div>

        <Formik
          initialValues={{
            Password: "",
            PasswordConfirm: "",
          }}
          validationSchema={DisplayingErrorMessagesSchema}
          onSubmit={(values) => {
            axios
              .post(api + "/api/recovery", {
                token: params.token,
                password: values.Password,
              })
              .then(function (response) {
                if (response.data === SUCCESS) {
                  handleOpen();
                } else {
                  handleClose();
                }
              })
              .catch(function (error) {
                handleClose();
              });
          }}
        >
          {({ errors, touched }) => (
            <Form id="np-login-form">
              <div className="center-items">
                <div className="container-label mt-label-password">
                  {PASSWORD_CAPITAL_LETTER}
                </div>
                <Field className="np-input" name="Password" type="Password" />
              </div>

              {touched.Password && errors.Password && (
                <div className="login-error center-items mb-error">
                  {errors.Password}
                </div>
              )}

              <div className="center-items">
                <div className="container-label">
                  {CONFIRM}
                  <br />
                  {PASSWORD}
                </div>
                <Field
                  className="np-input"
                  name="PasswordConfirm"
                  type="Password"
                />
              </div>

              <Typography id="np-description" align="justify">
                {DESCRIPTION_PASSWORD}
              </Typography>

              {touched.PasswordConfirm && errors.PasswordConfirm && (
                <div className="login-error-confirm-password mb-error">
                  {errors.PasswordConfirm}
                </div>
              )}

              <button className="btn-ro-modal" type="submit">
                {SAVE}
              </button>
            </Form>
          )}
        </Formik>
      </Box>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleNewPassword}>
          <div>
            <div className="center-items">
              <img src={Logo} id="rp-logo" alt="" />
            </div>
            <div id="rp-title">{SUCCESS_NEW_PASSWORD}</div>
            <div className="center-items">
              <button className="btn-ro-modal-center" onClick={handleClose}>
                {ACEPT}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
