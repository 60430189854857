const LOADER_VALIDATE_DATA = "Se están validando sus datos";
const LOADER_WAIT_A_MOMENT = "Espere un momento...";
const VALIDATION_SHIPPING = "Debe escoger una línea naviera";

const RECOVER_PASSWORD = "Recuperación de contraseña";
const MESSAGE_SENDLINK = "Se enviará el link a tu correo para reestablecer tu contraseña";
const SEND = "Enviar";
const NEW_PASSWORD = "Nueva contraseña";
const PASSWORD_CAPITAL_LETTER = "Contraseña";
const PASSWORD = "Contraseña";
const CONFIRM = "Confirmar";
const DESCRIPTION_PASSWORD = "Debe contener mínimo: 1 mayúscula, 1 minúscula, números, signo especial (*-_#.)";
const SAVE = "Guardar";
const SUCCESS_NEW_PASSWORD = "Se cambio la contraseña satisfactoriamente";
const SUCCESS = "Success";
const ACEPT = "Acepto";

export {
  LOADER_VALIDATE_DATA,
  LOADER_WAIT_A_MOMENT,
  RECOVER_PASSWORD,
  MESSAGE_SENDLINK,
  SEND,
  NEW_PASSWORD,
  PASSWORD_CAPITAL_LETTER,
  PASSWORD,
  CONFIRM,
  DESCRIPTION_PASSWORD,
  SAVE,
  SUCCESS_NEW_PASSWORD,
  SUCCESS,
  ACEPT,
  VALIDATION_SHIPPING
};
